<template>
  <div>
    <div class="set-info">
      <!-- 时长设置 -->
      <p>设置信息</p>
      <section>
        <div class="left-title">时长设置</div>
        <el-form class="set-time" :model="form1" :inline="true">
          <el-form-item label="是否预警" required>
            <el-select v-model="form1.duration" placeholder="请选择">
              <el-option v-for="item in durationOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="预警分析时长" v-show="form1.duration == 1">
            <!-- 展示模态分析的时间间隔（天） -->
            <el-input v-model="arr[form1.period - 1]" disabled />
            <span class="unit">天</span>
          </el-form-item>
        </el-form>
      </section>
      <!-- 阈值设置 -->
      <section class="set-threshold">
        <div class="left-title">阈值设置</div>
        <div class="form-list">
          <span class="threshold-title">预警标度界限</span>
          <span>结构理论计算值频率</span>
          <el-form :model="form2" ref="form2" :rules="rule2">
            <div class="limit-value">
              <el-form-item label="黄色预警">
                <span> 监测值≥</span>
                <span>0.90</span>
              </el-form-item>
              <el-form-item label="橙色预警">
                <span> 监测值≥</span>
                <span>1.00</span>
              </el-form-item>
              <el-form-item label="红色预警">
                <span> 监测值≥</span>
                <span>1.10</span>
              </el-form-item>
            </div>
            <div class="limit-value">
              <el-form-item prop="frequency">
                <el-input v-model="form2.frequency" placeholder="请输入" />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </section>
    </div>
    <div class="btns">
      <el-button :class="!myOption ? 'disable_icon' : ''" @click="handleSave">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form1: { duration: 0 },
      arr: ["10", "20", "30"],
      form2: {},
      projectId: "",
      disabled: false,
      durationOptions: [
        { id: 0, name: "不预警" },
        { id: 1, name: "预警" }
      ],
      rule2: {
        frequency: [{ required: true, message: "请输入", trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.projectId = sessionStorage.getItem(projectId);
    // this.projectId = this.$store.state.projectId
    // if(sessionStorage.getItem('isAdmin') == 1) {
    this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;
    // } else {
    // this.disabled = true
    // }
    this.getData();
  },
  methods: {
    getData() {
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdPerformance/${this.projectId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form1.period = res.data.period;
          this.form1.duration = res.data.duration ? res.data.duration : 0;
          this.form2 = res.data;
        }
      });
    },
    handleSave() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      if (this.disabled) return;
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          this.form2.duration = this.form1.duration;
          this.$axios.post(`${this.baseURL}setttings/settingsThresholdPerformance/${this.projectId}`, this.$qs.stringify(this.form2)).then((res) => {
            res = res.data;
            if (res.errCode == 200) {
              this.$message({
                message: "保存成功！",
                type: "success"
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label {
    padding: 0;
    width: 134px;
    text-align: left;
    line-height: 52px;
    white-space: nowrap;
    background: transparent;
  }
  .set-threshold .el-form-item__label {
    width: 100px;
  }
  .el-form-item__content {
    flex: 1;
    color: #a8aaab;
    text-align: left;
    line-height: 52px;
  }
  .el-form-item__error {
    top: 40px;
  }
  .el-input,
  .el-input__inner {
    padding: 0;
    width: 130px;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  .el-input.is-disabled .el-input__inner {
    border-color: rgba(151, 151, 151, 0.29);
  }
}

p {
  color: #fff;
  line-height: 40px;
  text-align: center;
}
.set-info {
  border-radius: 8px;
  margin-bottom: 26px;
  margin: 24px 4px 4px;
  border: 1px solid #5e5e5f;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  section {
    display: flex;
    border-top: 1px solid #5e5e5f;
    .left-title {
      width: 152px;
      display: flex;
      color: #fff;
      line-height: 52px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      background: #0c265a;
    }
  }
  .set-time {
    flex: 1;
    padding-left: 2%;
    .unit {
      padding: 0 12px;
      white-space: nowrap;
    }
  }
  .el-form-item {
    margin: 0;
    margin-right: 20px;
  }
}
.form-list {
  flex: 1;
  padding: 20px 0 23px 2%;
  .el-form {
    display: flex;
    .el-form-item {
      height: 52px;
    }
  }
  .threshold-title,
  .limit-value {
    width: 300px;
    display: inline-block;
  }
}
.btns {
  margin-top: 44px;
  text-align: center;
  .el-button {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
}
</style>
